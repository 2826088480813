import React, {useEffect } from 'react';
import Slider from "react-slick";
import "../css/imageSlider.css";
import BUS_1 from "../images/BUS_1.jpg";
import BUS_2 from "../images/BUS_2.jpg";
import BUS_3 from "../images/BUS_3.jpg";
import BUS_4 from "../images/BUS_4.jpg";
import BUS_5 from "../images/BUS_5.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



const ImageSlider = (props) => {

    var settings = {
        infinite:true,
        centerMode: true,
        variableWidth: true,
        vertical: false,
        dots: false,
        speed: 15000,
        cssEase: 'linear',
        pauseOnHover:false,
        useTransform:false,
        autoplay: true,
        autoplaySpeed: 0,
        // arrows: true,
        // mobileFirst:true,
        rows:1,
    };
    
    useEffect(() => { 
    }, []);

   
    return (
        <Slider {...settings} className="slider" >
            <div className="imgContainer"><img src={BUS_1} className="displayImage" alt=""/></div>
            <div className="imgContainer"><img src={BUS_2} className="displayImage" alt=""/></div>
            <div className="imgContainer"><img src={BUS_3} className="displayImage" alt=""/></div>
            <div className="imgContainer"><img src={BUS_4} className="displayImage" alt=""/></div>
            <div className="imgContainer"><img src={BUS_5} className="displayImage" alt=""/></div>
        </Slider>
    )
}


export default ImageSlider